import { themeGet } from '@styled-system/theme-get'
import { Modal } from 'antd'
import { Text } from 'components/Text'
import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'

export interface SectionWrapperProps {
    display?: 'grid' | 'flex' | 'block' | 'none'
    flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
    justifyContent?:
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'initial'
        | 'space-around'
        | 'space-between'
        | 'stretch'
    alignItems?: 'center' | 'flex-start' | 'flex-end' | 'initial' | 'stretch'
    padding?: string
    gap?: string
    overflow?: string
    maxHeight?: string
}

export const ModalWrapperAnt = styled(Modal)`
    .ant-modal-content {
        background: transparent;
        padding: 0px 10px;

        ul > li {
            color: white;
            list-style: disc;
        }

        .ant-modal-body {
            & > div:first-child {
                display: flex;
                flex-direction: column !important;
                @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
                    flex-direction: row;
                }
            }
        }

        .ant-modal-header {
            background: #1b1b25;
            border: none;
            .ant-modal-title {
                color: white;
                font-size: 1.6em;
                font-weight: 700;
            }
        }

        .ant-modal-footer {
            border: none;
            display: flex;
            padding-bottom: 20px;
            justify-content: flex-end;
        }

        .ant-modal-close-x {
            display: none !important;
        }
    }
`

export const ModalWrapper = styled(Modal)`
    padding: 0;
    .ant-modal {
    }
    .ant-modal-close-x {
        font-size: 20px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ant-modal-content {
        background: none;
    }
    .ant-modal-body {
        background-color: ${themeGet('colors.gray.900')};
        border-radius: 8px;
        padding: 0;
    }
`

export const ModalContent = styled.div<{ show: boolean }>`
    border-radius: 4px;
    transition: all ease 0.5s;
    /* ${({ show }) =>
        show
            ? css`
                  display: flex;
              `
            : css`
                  display: none;
              `}; */
    /* padding: 0; */
    /* position: fixed; */
    /* height: auto; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* width: 90vw; */
    /* max-width: 600px; */
    /* height: 90vh; */
    /* max-height: 800px; */
`

export const ModalBody = styled.div`
    padding: 24px;
    /* margin: auto; */
    /* width: 90vw; */
    /* height: 90vh; */
    /* max-height: 800px; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-around; */
    /* max-width: 600px; */
    /* padding: 5px 40px 5px 40px; */
    /* background-color: ${themeGet('colors.gray.900')}; */
`

export const SectionWrapper = styled.div<SectionWrapperProps>`
    display: ${({ display = 'flex' }) => display};
    flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
    justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
    align-items: ${({ alignItems = 'flex-start' }) => alignItems};
    padding: ${({ padding }) => padding || '0'};
    gap: ${({ gap }) => gap || '0'};
    overflow-y: ${({ overflow }) => overflow || 'visible'};
    max-height: ${({ maxHeight }) => maxHeight || '100%'};

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 1px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: white;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
`

export const ClubSectionWrapper = styled(SectionWrapper)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
`

export const ModalHeader = styled.div`
    display: flex;
    justify-content: right;
`

export const ModalFooter = styled.div`
    padding: 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    background: ${themeGet('colors.black.300')};
    .footer-text {
        font-size: 24px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 15px;
        .footer-text {
            font-size: 20px;
        }
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        padding: 10px;
        .footer-text {
            font-size: 14.5px;
        }
    }
`

export const ClubNameWrapper = styled(Text)`
    color: orange;
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        text-align: center;
    }
    text-align: center;
`
