import { Image } from 'components/Image'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

export const ItemCardWrapper = styled.a`
    .item-card-avatar div {
        transition: 0.2s ease-out;
    }

    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        &:hover .item-card-tile {
            background-color: #1d1c21;
        }

        &:hover .item-card-image {
            scale: 1;
        }

        &:hover .item-card-avatar div {
            scale: 1.07;
        }
    }
`

export const ItemCardTile = styled.div`
    width: 164px;
    height: 164px;
    background-color: #16151a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        height: 140px;
        width: 140px;
    }
`

export const ItemCardImage = styled(Image)`
    scale: 0.93;
    transition: 0.2s ease-out;
`

export const ItemCardFooter = styled.div`
    padding: 10px 5px;
    width: 164px;

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 140px;
    }
`
